.slide-out-top {
  -webkit-animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.delay-1 {
  animation-delay: 0.2s;
}
.delay-2 {
  animation-delay: 0.4s;
}
.delay-3 {
  animation-delay: 0.6s;
}
.delay-4 {
  animation-delay: 0.8s;
}
.delay-5 {
  animation-delay: 1s;
}
.delay-6 {
  animation-delay: 1.2s;
}
.delay-7 {
  animation-delay: 1.4s;
}
.delay-8 {
  animation-delay: 1.6s;
}
.delay-9 {
  animation-delay: 1.8s;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-9-11 10:26:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1.5) translateY(-100px) translateX(100px);
    transform: scale(1.5) translateY(-100px) translateX(100px);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1.5) translateY(-100px) translateX(100px);
    transform: scale(1.5) translateY(-100px) translateX(100px);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
}
