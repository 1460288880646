.feedback-container {
  position: fixed;
  right: 40px;
  bottom: 60px;
  z-index: 1000;
}

.feedback-popover .ant-form-item-label label {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
}

.feedback-popover textarea {
  border-radius: 12px;
}

.feedback-popover textarea::placeholder {
  color: #777e91;
}

.ant-popover-content {
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
}

.feedback-popover .feedback-submit-button.ant-btn,
.edit-tags-modal .feedback-submit-button.ant-btn {
  background: linear-gradient(277.47deg, rgb(246, 211, 101) -38.63%, rgb(255, 136, 17) 101.85%);
  border-radius: 90px;
  width: 126px;
  height: 48px;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  border: none;
}
/* 
.feedback-popover .feedback-submit-button:hover {
  background-color: #3f3d75;
  border-color: #3f3d75;
} */
