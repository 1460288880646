.ant-collapse.comments-collapse {
  background: #fff;
}

.ant-collapse.comments-collapse .ant-collapse-item {
  border: none;
}

.ant-collapse.comments-collapse .ant-collapse-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ant-collapse.comments-collapse .ant-collapse-content-box {
  padding: 0;
}

.detail-drawer .ant-drawer-content {
  border-radius: 18px 18px 0 0;
}

.detail-drawer .ant-drawer-body {
  padding: 16px;
}

.post-detail-card #comment {
  font-size: 28px;
}

.img-shadow {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3), -2px 2px 2px rgba(0, 0, 0, 0.3);
}

.credit-title {
  display: inline-block;
  width: 3px;
  height: 20px;
  border-radius: 2px;
  background: rgba(255, 183, 0, 1);
  margin-right: 6px;
}

.ant-tag.post-detail-tag {
  background: rgba(255, 183, 0, 1);
  border: none;
  color: white;
  line-height: 18px;
}

.emoji-fixed {
  position: fixed;
  top: 0;
  z-index: 10001;
}
