.comment-icon {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHdpZHRoPSI0MiIgaGVpZ2h0PSI0NiI+PGRlZnM+PGZpbHRlciBpZD0icHJlZml4X19hIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiIgeD0iMSIgeT0iMSIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIj48ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIvPjxmZU9mZnNldCBkeT0iMiIvPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjIiLz48ZmVDb2xvck1hdHJpeCB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAuMDc5OTk5OTk4MjExODYwNjYgMCIvPjxmZUJsZW5kIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvdyIvPjxmZUJsZW5kIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvdyIgcmVzdWx0PSJzaGFwZSIvPjwvZmlsdGVyPjwvZGVmcz48ZyBmaWx0ZXI9InVybCgjcHJlZml4X19hKSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOnBhc3N0aHJvdWdoIj48cGF0aCBkPSJNNSAzMy40NzZDNSAzNC4zMTggNS42ODIgMzUgNi41MjQgMzVIMjFjOC44MzcgMCAxNi03LjE2MyAxNi0xNlMyOS44MzcgMyAyMSAzIDUgMTAuMTYzIDUgMTl2MTQuNDc2eiIgZmlsbD0iIzI4NzhGRiIvPjxwYXRoIGQ9Ik00IDMzLjQ3NlYxOXEwLTMuNDU4IDEuMzM2LTYuNjE3IDEuMjktMy4wNTEgMy42NDMtNS40MDQgMi4zNTMtMi4zNTIgNS40MDQtMy42NDNRMTcuNTQyIDIgMjEgMnQ2LjYxNyAxLjMzNnEzLjA1MSAxLjI5IDUuNDA0IDMuNjQzIDIuMzUyIDIuMzUzIDMuNjQzIDUuNDA0UTM4IDE1LjU0MiAzOCAxOXQtMS4zMzYgNi42MTdxLTEuMjkgMy4wNTEtMy42NDMgNS40MDQtMi4zNTMgMi4zNTItNS40MDQgMy42NDNRMjQuNDU5IDM2IDIxIDM2SDYuNTI0cS0xLjA0NiAwLTEuNzg1LS43NFE0IDM0LjUyMyA0IDMzLjQ3N3ptMiAwcTAgLjIxNy4xNTMuMzcuMTU0LjE1NC4zNy4xNTRIMjFxMy4wNTIgMCA1LjgzOC0xLjE3OCAyLjY5Mi0xLjEzOSA0Ljc2OS0zLjIxNSAyLjA3Ni0yLjA3NyAzLjIxNS00Ljc2OVEzNiAyMi4wNTIgMzYgMTl0LTEuMTc4LTUuODM4cS0xLjEzOS0yLjY5Mi0zLjIxNS00Ljc2OS0yLjA3Ny0yLjA3Ni00Ljc2OS0zLjIxNVEyNC4wNTIgNCAyMSA0dC01LjgzOCAxLjE3OHEtMi42OTIgMS4xMzktNC43NjkgMy4yMTUtMi4wNzYgMi4wNzctMy4yMTUgNC43NjlRNiAxNS45NDggNiAxOXYxNC40NzZ6IiBmaWxsPSIjRkZGIi8+PC9nPjwvc3ZnPg==)
    4px 2px no-repeat;
}

.ant-btn.ant-btn-primary {
  background: #1890ff;
}

.comment-dialog-arrow {
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBmaWxsPSJub25lIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIxNy4xNjgxMTQxODUzMzMyNTIiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAxNy4xNjgxMTQxODUzMzMyNTIgMjQiPjxkZWZzPjxmaWx0ZXIgaWQ9Im1hc3Rlcl9zdmcwXzBfMTA2MyIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiIHg9Ii0xLjUxMDAzNzAyODIyODg0ODhlLTE1IiB5PSIwIiB3aWR0aD0iMTUuMTY4MTE0MTg1MzMzMjU0IiBoZWlnaHQ9IjI0Ij48ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIi8+PGZlT2Zmc2V0IGR5PSIxIiBkeD0iMCIvPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjIiLz48ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4yMDAwMDAwMDI5ODAyMzIyNCAwIi8+PGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJlZmZlY3QxX2Ryb3BTaGFkb3ciLz48ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvdyIgcmVzdWx0PSJzaGFwZSIvPjwvZmlsdGVyPjwvZGVmcz48ZyBmaWx0ZXI9InVybCgjbWFzdGVyX3N2ZzBfMF8xMDYzKSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOnBhc3N0aHJvdWdoIj48cGF0aCBkPSJNMTAuNTg2MTYsMy41ODU0MTRDMTEuMzY3MTIsMi44MDQ4NjMsMTIuNjMyODgsMi44MDQ4NjEsMTMuNDEzODQsMy41ODU0MTRDMTMuNDEzODQsMy41ODU0MTQsMjAsMTAuMTY4MTEsMjAsMTAuMTY4MTFDMjAsMTAuMTY4MTEsNCwxMC4xNjgxMSw0LDEwLjE2ODExQzQsMTAuMTY4MTEsMTAuNTg2MTYsMy41ODU0MTQsMTAuNTg2MTYsMy41ODU0MTRDMTAuNTg2MTYsMy41ODU0MTQsMTAuNTg2MTYsMy41ODU0MTQsMTAuNTg2MTYsMy41ODU0MTRaIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHRyYW5zZm9ybT0ibWF0cml4KC02LjI5MTgyMDk1MDk1MzUzN2UtMTcsMSwxLDUuOTU5MTY0NjQyNzg3MTcxZS0xNywxLjAwMDAwMDAwMDAwMDAwMDQsLTEpIiBmaWxsPSIjRkZGRkZGIiBmaWxsLW9wYWNpdHk9IjEiLz48L2c+PC9zdmc+)
    left top no-repeat;
  width: 7px;
  height: 22px;
  left: -7px;
  top: 24px;
}

.stroke-outside {
  text-shadow: 0 1px black, 1px 0 black, -1px 0 black, 0 -1px black;
  word-break: keep-all;
}

.fade-out {
  -webkit-animation: fade-out 1s ease-out both;
  animation: fade-out 1s ease-out both;
  animation-delay: 3s;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-8-31 14:11:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ant-space-item .ant-switch {
  background: rgba(0, 0, 0, 0.25);
}
.ant-space-item .ant-switch-checked {
  background: #1890ff;
}
