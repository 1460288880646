* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  /* background-color: rgb(242, 242, 242); */
  background-color: white;
}

.waterfall-container {
  max-width: 1264px;
  margin: 0 auto;
}

.waterfall-container .mySwiper {
  max-width: 1264px;
  width: 100%;
}
.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  color: gray;
}

.mySwiper .note-img {
  /* max-height: 320px; */
  object-fit: cover;
}
@media (max-width: 640px) {
  /* .mySwiper .note-img {
    max-height: 240px;
    height: 240px;
  } */
}

.display-mode-grid {
  /* -moz-column-width: 18em; */
  column-width: 320px;
  column-gap: 50px;
}

.display-mode-flex {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.comments-modal .comments-container {
  max-height: 60vh;
  overflow-y: scroll;
}

.comments-modal .ant-modal-content {
  border-radius: 24px;
  overflow: hidden;
}

.rank-number-container {
  background-image: url(../../assets/number-star.png);
  background-size: 100% 100%;
}
