@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~antd/dist/antd.css";

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(242, 242, 242); */
  background-color: white;
}

body * {
  font-family: helvetica-neue;
}

.bg-main {
  /* background-color: rgb(242, 242, 242); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

html {
  transition: color 300ms, background-color 300ms;
}

html[theme="dark-mode"] {
  filter: invert(1) hue-rotate(180deg);
}

html[theme="dark-mode"] img,
html[theme="dark-mode"] .emoji-icon,
html[theme="dark-mode"] div[aria-label="animation"],
html[theme="dark-mode"] .lottie-icon,
html[theme="dark-mode"] div.no-dark-mode {
  filter: invert(1) hue-rotate(180deg);
}

#root .ant-layout {
  background-color: #fff;
}

/* @font-face {
  font-family: "Merriweather";
  src: url("./assets/font/Merriweather-Regular.ttf");
} */

/* .merriweather-font {
  font-family: "Merriweather", sans-serif !important;
} */

@font-face {
  font-family: "helvetica-neue";
  src: url("./assets/font/Helvetica-Neue.ttf");
}

.helvetica-font {
  font-family: "helvetica-neue", sans-serif !important;
}

.ant-btn.main-button {
  background: #2a294f;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  border-color: #2a294f;
}

.ant-btn.main-button:hover {
  background-color: #3f3d75;
  border-color: #3f3d75;
}

.common-page-container {
  max-width: 1440px;
  margin: 0 auto;
}

.with-2-col {
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.with-3-col {
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.ant-upload-list-picture-card {
  padding-top: 8px;
}

.top-post-select .ant-select-selector {
  border-radius: 9999px !important;
  background: linear-gradient(277.47deg, #f6d365 -38.63%, #ff8811 101.85%) !important;
  color: #fff;
}

.top-post-select .ant-select-selection-item {
  font-weight: bold;
}

.top-post-select .ant-select-arrow {
  color: white;
}

.ant-back-top-content {
  background: linear-gradient(277.47deg, #f6d365 -38.63%, #ff8811 101.85%);
}

.note-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.11) 0px 0px 2.9px 0px, rgba(0, 0, 0, 0.2) 0px 1.6px 3.6px 0px;
}
.ny-times {
  display: inline-block;
  width: 12px;
  height: 14px;
  margin-right: 4px;
}

.seeking-alpha-icon {
  padding: 0 7px;
  margin-right: 8px;
  border: 1px solid #ff7200;
  background: #ff7200;
  border-radius: 2px;
  display: inline-block;
  box-sizing: border-box;
  height: auto;
  font-size: 12px;
  line-height: 20px;
}
.seeking-alpha-icon > svg {
  display: inline-block;
  height: 16px;
  width: 88px;
}

.movies-tags {
  display: flex;
  align-items: center;
}
.movies-icon {
  margin-right: 8px;
  display: inline-block;
  box-sizing: border-box;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  padding: 4px 0;
}

.movies-icon > img {
  height: 12px;
  vertical-align: bottom;
  font-size: 0px;
}

@media (max-width: 500px) {
  .ant-tag {
    height: 18px;
    font-size: 11px;
    line-height: 16px;
  }

  .seeking-alpha-icon {
    height: 18px;
    line-height: 16px;
  }

  .seeking-alpha-icon > svg {
    height: 12px;
    width: 66px;
  }
}

.news-tag {
  background: transparent;
  color: #fff;
}

.news-tag-black {
  color: #000;
}

.no-user-select {
  -webkit-touch-callout: none;
  /*系统默认菜单被禁用*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -khtml-user-select: none;
  /*早期浏览器*/
  -moz-user-select: none;
  /*火狐*/
  -ms-user-select: none;
  /*IE10*/
  user-select: none;
}

.no-img-emoji-container {
  margin-top: 8px;
}
.no-img-emoji-container > div {
  max-width: 128px !important;
}

.note-detail-more .note {
  margin-bottom: 16px;
}

.switch-emoji-drawer .ant-drawer-body {
  padding: 12px 16px;
}

/* 上传裁切 */
.avatar-upload-crop .ant-upload-list {
  display: none;
}

.social-button {
  background-color: rgb(239, 240, 242);
  color: #000;
}

.note-btn {
  background-color: rgb(59, 60, 54);
}

@media (max-width: 500px) {
  .note-btn svg {
    width: 16px;
    height: 16px;
  }
}

.ant-message .anticon {
  top: -3px;
}

.ant-message .ant-message-notice-content {
  border-radius: 8px;
}

.linear-bg {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9) 0,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}

.note.text-note {
  padding: 16px 16px;
  border: 1px solid rgba(25, 25, 25, 0.12);
  cursor: pointer;
}

@media (max-width: 500px) {
  .note.text-note {
    padding: 8px 8px 0;
  }
}
