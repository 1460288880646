.post-emoji-popover .ant-popover-content {
  border-radius: 6px;
}
/* .post-emoji-popover .ant-popover-inner {
  background: #000;
} */

.post-emoji-popover .ant-popover-inner-content {
  /* color: #fff; */
  padding: 8px 12px;
}
