.author-card-wrapper {
  background-image: url("../../assets/images/ranking-banner-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
}

.author-card .user-brief {
  font-size: 16px;
  line-height: 140%;
}

.newtag-container {
  text-align: center;
}

.newtag-container .ant-btn {
  margin-top: 20px;
}

.following-user-avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 8px;
}

.following-user-avatar img {
  width: 40px;
  height: 40px;
}

.edit-profile-content .edit-profile-label {
  margin-bottom: 2px;
}

.edit-profile-content .edit-profile-avatar {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 8px;
}

.edit-profile-content .edit-profile-avatar img {
  width: 60px;
  height: 60px;
}

.edit-profile-avatar-change {
  position: relative;
  width: 70px;
  height: 30px;
  cursor: pointer;
}

.edit-profile-avatar-change .ant-btn {
  width: 70px;
  height: 30px;
}

.edit-profile-avatar-change input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
  cursor: pointer;
}

.ant-btn.ant-btn-primary.follow-button {
  background: #6432f9;
  border: 0;
  min-width: 90px;
}

.profile-tab-active {
  position: relative;
}
.profile-tab-active::after {
  content: " ";
  position: absolute;
  bottom: 0;
  width: calc(100% - 16px);
  height: 3px;
  background: #ff8811;
  left: 8px;
  border-radius: 10px;
}
