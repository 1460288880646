.h5-tab.ant-tabs-left > .ant-tabs-nav {
  min-width: 36px;
}
.h5-tab.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
}

.pc-tab.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 16px 8px 0;
}

.ant-collapse .ant-collapse-item.site-collapse-custom-panel .ant-collapse-header {
  padding-left: 0;
  padding-right: 0;
}

.ant-collapse .ant-collapse-item.site-collapse-custom-panel .ant-collapse-content-box {
  padding-left: 0;
  padding-right: 0;
}
