.banner-container {
  width: 100%;
  height: 428px;
  background-image: url(../../assets/images/ranking-banner-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.emoji-popover .ant-popover-content {
  border-radius: 12px;
  /* box-shadow: none; */
}
