.ant-layout .ant-layout-header {
  background: white;
}
.ant-layout .ant-layout-header .nav-bg {
  background-image: url("../../assets/images/nav-bg.png");
  background-size: 100% auto;
  background-position: center top;
}
.post-comment-modal .ant-modal-content,
.post-modal .ant-modal-content,
.edit-tags-modal .ant-modal-content,
.signup-modal .ant-modal-content,
.login-modal .ant-modal-content {
  border-radius: 24px;
  overflow: hidden;
}

.logout-modal .ant-modal-confirm-body .ant-modal-confirm-title {
  overflow: visible;
}

.login-form .ant-form-item-label label {
  font-size: 12px;
  font-weight: 500;
  color: #23262f;
}

.login-form .ant-form-item {
  margin-bottom: 14px;
}

.login-form .ant-select-selector {
  height: 42px !important;
  border-radius: 12px !important;
}

.login-form .ant-select-selection-item,
.login-form .ant-select-selection-placeholder {
  line-height: 40px !important;
}

.login-form .ant-picker {
  width: 100%;
}

.login-form input,
.login-form .ant-picker {
  height: 44px;
  border-radius: 12px;
}

.login-form .ant-input-password {
  height: 44px;
  border-radius: 12px;
}

.login-form .ant-input-password input {
  height: 34px;
  border-radius: 0px;
}

/* .login-form .ant-form-item {
  margin-bottom: 20px;
} */

.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2a294f;
  border-color: #2a294f !important;
}

.ant-checkbox:hover::after {
  border-color: #2a294f !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: #2a294f !important;
}

.login-form .login-button.ant-btn {
  background: #2a294f;
  border-radius: 90px;
  max-width: 270px;
  width: 100%;
  height: 60px;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  border-color: #2a294f;
}

.login-form .login-button:hover {
  background-color: #3f3d75;
  border-color: #3f3d75;
}

.menu-drawer .ant-drawer-body {
  padding: 24px 0;
}

.msg-popover .ant-popover-content {
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 1px 2px 2px 0 rgb(0 0 0 / 10%);
  border-width: 1px;
}

.msg-popover .ant-popover-arrow {
  opacity: 0;
}

.msg-popover .ant-popover-inner-content {
  padding: 20px;
}

.notice-button {
  position: relative;
}

.notice-button::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #53c22b;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 5px;
}

.home-banner-container {
  background: url(../../assets/images/home-banner-bg.png) no-repeat;
  background-size: 1248px 100%;
  background-position: center;
  overflow: hidden;
}

.home-banner-container .home-banner-ball {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
}

.header-select .ant-select-selector {
  border-radius: 100px !important;
  /* min-width: 108px; */
  background: rgba(255, 248, 233, 0.6) !important;
  border-color: #f6d365 !important;
}

.header-select .ant-select-arrow {
  color: #000;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft .ant-select-item-option-selected {
  background: rgba(255, 248, 233, 0.6);
}

.emoji-select .ant-select-selector {
  height: 72px !important;
}

.ant-collapse.header-emojis-collapse {
  background: #fff;
}

.ant-collapse.header-emojis-collapse .ant-collapse-item {
  border: none;
}

.ant-collapse.header-emojis-collapse .ant-collapse-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ant-collapse.header-emojis-collapse .ant-collapse-content-box {
  padding: 0;
}

.ant-collapse.header-emojis-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  font-size: 18px;
}
.ant-collapse.header-emojis-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  transform: rotate(90deg);
}

.ant-collapse-item-active .ant-collapse-header .ant-collapse-arrow > svg {
  transform: rotate(-90deg) !important;
}

.header-emojis-collapse.ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  top: 34%;
  width: 30px;
  height: 30px;
  padding: 6px;
  color: #fff;
  border-radius: 50%;
  background: linear-gradient(277.47deg, rgb(246, 211, 101) -38.63%, rgb(255, 136, 17) 101.85%);
}

@media (max-width: 500px) {
  .header-emojis-collapse.ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    top: 100px;
    left: calc(50% - 15px);
  }
}

.home-emoji-popover .ant-popover-inner,
.home-emoji-popover .ant-popover-arrow-content {
  background-color: rgb(51 65 85);
}
.home-emoji-popover .ant-popover-arrow-content::before {
  background: linear-gradient(to left, rgb(51 65 85) 50%, rgb(51 65 85) 50%) no-repeat -5px -5px;
}
.home-emoji-popover .ant-popover-inner-content {
  /* color: #fff; */
  padding: 4px 16px;
}

.right-arrow,
.note-right-arrow,
.note-right-arrow-pc {
  z-index: 49;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.right-arrow::before {
  height: 72px;
  width: 50px;
  content: "";
  pointer-events: none;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.98) 20%, rgba(255, 255, 255, 0) 80%);
}

.note-right-arrow::before {
  height: 32px;
  width: 20px;
  content: "";
  pointer-events: none;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.98) 20%, rgba(255, 255, 255, 0) 80%);
}
.note-right-arrow-pc {
  height: 46px;
}
.note-right-arrow-pc::before {
  height: 46px;
  width: 20px;
  content: "";
  pointer-events: none;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.98) 20%, rgba(255, 255, 255, 0) 80%);
}

.dark-theme .note-right-arrow-pc::before {
  background: linear-gradient(to left, rgba(54, 57, 63, 0.98) 20%, rgba(54, 57, 63, 0) 80%);
}
.dark-theme .note-right-arrow::before {
  background: linear-gradient(to left, rgba(54, 57, 63, 0.98) 20%, rgba(54, 57, 63, 0) 80%);
}
.dark-theme .right-arrow::before {
  background: linear-gradient(to left, rgba(54, 57, 63, 0.98) 20%, rgba(54, 57, 63, 0) 80%);
}
.dark-theme .note-right-arrow-pc::after {
  height: 46px;
  width: 8px;
  content: "";
  pointer-events: none;
  background: rgba(54, 57, 63, 1);
}

.note .dark-theme > div {
  padding-bottom: 6px;
}

.menu-popover .ant-popover-inner-content {
  padding: 4px 20px;
  background: rgb(51, 65, 85);
  color: white;
}

.menu-popover .ant-popover-arrow-content,
.menu-popover .ant-popover-arrow-content::before {
  background: rgb(51, 65, 85);
}

@media (max-width: 640px) {
  .search-input .ant-input {
    background-color: rgb(226, 232, 240);
  }
}
